<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="container">
      <div class="row justify-content-center mb-4">
        <h3>Licenses Pending for Renewals</h3>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3 ml-0 p-0">
        <multiselect v-model="oficeFilter" @input="filterData"
         :options="offices"
         :multiple="true"
         :close-on-select="false"
         :clear-on-select="false"
         :preserve-search="true"
          placeholder="Select Offices"
          label="office_name" 
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.office_name}).join(", ") }}</span></template>
        </multiselect>
      </div>
      <div v-if="clients.length>0" class="col-3 ml-2 p-0">
        <multiselect v-model="clientFilter" @input="filterData" 
          :options="clients" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true" 
          placeholder="Select Client" 
          label="name" 
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.name}).join(", ") }}</span></template>
        </multiselect>
      </div>
    </div>
    <el-table 
    :data="tableData"
    class="w-100">
     <el-table-column sortable prop="corporate.office.office_name" label="Office"></el-table-column>
     <el-table-column sortable prop="corporate.name" label="Client"></el-table-column>
     <el-table-column sortable prop="license_type.name" label="Licence Type"></el-table-column>
     <el-table-column sortable prop="dueDate" label="Remaining Days">
        <template slot-scope="scope">{{ 
              diffDays(scope.row.dueDate)
           }}
        </template>
      </el-table-column>
      <el-table-column sortable prop="dueDate" label="Exp Date">
        <template slot-scope="scope">{{ scope.row.dueDate | moment("MM-DD-YYYY") }}</template>
      </el-table-column>
     <el-table-column sortable prop="responsible.name" label="Responsible"></el-table-column>
    </el-table>
  </div>
</template>


<script>
import Multiselect from 'vue-multiselect';
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import corporate from "@/services/api/corporate";
import license from "@/services/api/license";
import user from "@/services/api/user";
import 'vue2-datepicker/index.css';

export default {
  components: {
    Multiselect
  },
  data() {    
    return {  
      data:[],    
      tableData: [],
      oficeFilter:[],
      lengthOfficeFilter: null,
      clientFilter:[],
      offices: [],
      clients: [],
      users: [],
      component: {
        data: null,
        drawer: false,
        render: 0,        
        index: null
      }
    };
  },
  mounted() {
  switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then(response => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then(response => {
            let idOffice=response[0].office.id;
            this.getClients([idOffice]);
        });
        break;
    }
    license.get().then(response => {
      this.data = response;
      /* var b = this.$moment(new Date()).format("YYYY-MM-DD");
      response.map(item => {
        var result = this.$moment(item.dueDate).diff(b, "days");
        if (this.$moment(item.dueDate).diff(b, "days") <= 25) {
          this.count += 1;
        }        
      }); */
    });
  },
  methods: {
    diffDays(dueDate){
      var start = new Date();
      var end = new Date(dueDate);

      var diff = new Date(end - start);
      var days=1;
      days = diff / 1000 / 60 / 60 / 24;


      if (isNaN(days)) {
          return 0
      } else {
          return Math.round(days + 1)
      }
    },
    getClients(idOffices){
      this.clients=[];
      idOffices.forEach(id=>{
        corporate.getClientsByOffice(id).then(response => {
          this.clients=this.clients.concat(response);
        });
      });
    },
    filterData(){
      let idOffices = this.oficeFilter.map(office=>{
        return office.id;
      });

      let clients = this.clientFilter.map(client=>{
        return client.id;
      });

      this.tableData = Object.assign([], this.data);

      if(idOffices.length>0){
        this.tableData=this.tableData.filter(item=>idOffices.indexOf(item.corporate.officeId)!=-1);
      }

      if(idOffices.length!==this.lengthOfficeFilter){
        this.lengthOfficeFilter=idOffices.length;
        this.getClients(idOffices);
      }

      if(idOffices.length==0){
        this.lengthOfficeFilter=0;
        this.clientFilter=[];
        this.clients=[];
      }

      if(clients.length>0){
        this.tableData=this.tableData.filter(item=>clients.indexOf(item.corporate.id)!=-1);
      }

    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>